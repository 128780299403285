import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock, StudyDesignOverview, StudyDesignArms } from '../index';

const ARCC = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview 
        title="Study Design"
        description="
        <p>Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 994 patients.<sup>1</sup></p>"
        data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>Patients with RCC at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions.</p>
                        <p>Nephrectomy</p>
                        <ul>
                            <li>Partial nephroprotective or radical complete nephrectomy with negative surgical margins ≥4 weeks prior to the time of screening.
                                <ul>
                                    <li>M1 NED patients also had complete resection of solid, isolated, soft tissue metastatic lesion(s).</li>
                                </ul>
                            </li>
                        </ul>
                        <p>Key Exclusion Criteria</p>
                        <ul>
                            <li>Patients were excluded from the trial if they had received prior systemic therapy for advanced RCC.</li>
                           <li>Patients with active autoimmune disease or a medical condition that required immunosuppression were also ineligible.</li>
                        </ul>
                        `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized (1:1) (N=994)',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray'
                },
                {
                    type: 'arrow-gray'
                }
            ],
        ]} />
        <StudyDesignArms title="Study Arms" arms={[
                {
                    title: "KEYTRUDA",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `KEYTRUDA (n=496) 200&nbsp;mg IV infusion Q3W`,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `Treatment continued for up to 1 year or until disease recurrence or unacceptable toxicity`
                            }
                        ]
                    ]
                },
                {
                    title: "Placebo",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `Placebo (n=498)`
                            }
                        ],
                        [
                            {
                                type: 'arrow-gray'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `<p>Treatment continued for up to 1 year or until disease recurrence or unacceptable toxicity</p>`
                            }
                        ]
                    ]
                }
        ]} />

        <StudyCopyBlock 
            title="End Points"
            description={` 
                <p>Major efficacy outcome measure:</p>
                <ul>
                    <li>Investigator-assessed disease-free survival, defined as time to recurrence, metastasis, or death (DFS)<sup>1,</sup><sup>d</sup></li>
                <ul>
                <p>Additional outcome measure:</p>
                <ul>
                    <li>Overall survival (OS)</li>
                <ul>
            `} 
        />

         <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <p>Baseline Characteristics of Patients (N=994)</p>
                <p>All patients randomized at baseline</p>
                <ul>
                    <li>Intermediate-high risk of recurrence: 86%</li>
                    <li>High risk of recurrence: 8%</li>
                    <li>M1 NED: 6%</li>
                    <li>Median age: 60 years (range: 25 to 84 years), 33% aged 65
                    years or older</li>
                    <li>Male: 71%</li>
                    <li>ECOG PS of 0: 85%; ECOG PS of 1: 15%</li>
                    <li>No nodal involvement (N0): 94%</li>
                    <li>Sarcomatoid features: 11%</li>
                    <li>Radical nephrectomy: 92%</li>
                    <li>Partial nephrectomy: 8%</li>
                    <li>Patients with T3 tumors<sup>2,e</sup>: 89%</li>
                </ul>
            `}
            footnotes={[
                {
                    label:'d.',
                    text: `DFS, as assessed by the investigator, is defined as the time from
                    randomization to the first documented local or distant recurrence of
                    RCC or death due to any cause, whichever occurred first.<sup>1</sup>`
                },
                {
                    label:'e.',
                    text: `90% (n=444/496) in the group receiving KEYTRUDA, 88% (n=437/498) in the placebo group.<sup>2</sup>`
                },
            ]}
            definitions={`
                ECOG PS = Eastern Cooperative Oncology Group performance status; 
                IV = intravenously; 
                M0 = no distant metastasis; 
                M1 = distant metastasis; 
                N0 = no regional lymph node metastasis; 
                N1 = regional lymph node involvement; 
                NED = no evidence of disease; 
                T3 = tumor extends into major veins or perinephric tissues, but not into the ipsilateral adrenal gland and not beyond Gerota's fascia; 
                Q3W = every 3 weeks.
            `} 
        />
    </ComponentWrapper>
);

export default ARCC ;