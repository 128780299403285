import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, SecondaryCallout, KMCurve, BoxedDefinitionList, ReferencesBlock, NCCNcallout } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import ARCC from '../../components/StudyDesign/__study-design/arcc'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-564 - Adjuvant Treatment for Certain Patients With Renal Cell Carcinoma (RCC) Clinical Trial Results`,
    keywords: `keynote 564, adjuvant treatment for certain patients with renal cell carcinoma clinical trial`,
    description: `Health care professionals may find clinical trial results from KEYNOTE-564 for the adjuvant treatment of certain patients with renal cell carcinoma (RCC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/adjuvant-renal-cell-carcinoma","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠564","description":"Clinical Findings from KEYNOTE⁠-⁠564","trialDesign":"Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 994 patients.1"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/adjuvant-renal-cell-carcinoma","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 29;
const jobCode = jobCodes[8].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'Advanced RCC', url: '/efficacy/advanced-renal-cell-carcinoma/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/adjuvant-renal-cell-carcinoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const KMofDFSFootnotes = [
    {
        label: "a.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "b.",
        text: "Based on stratified log-rank test."
    },
    {
        label: "c.",
        text: "<i>P</i> value (one-sided) is compared with a boundary of 0.0114."
    },
]

const boxedDefinitionListFootnotes = [
    {
        label: "f.",
        text: "Patients with metastatic disease who had undergone complete resection of primary and metastatic lesions."
    },
]

const patientInclusionCriteriaItems = [
    {
        header: "Intermediate-high risk of recurrence",
        description: `<p>pT2 with Grade 4 or sarcomatoid, N0, M0<sup>1</sup></p>
                      <p>pT3, any grade, N0, M0</p>
        `
    },
    {
        header: "High risk of recurrence",
        description: `<p>pT4, any grade, N0, M0</p>
                      <p>Any pT, any grade, N1, M0</p>
        `
    },
    {
        header: "M1 NED",
        description: `<p>M1 no evidence of disease<sup>f</sup></p>`
    },
]

const NCCNcalloutdefinitions = `
                                <p>Category 2A = Based upon lower-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate.<sup>4</sup></p>
                                <p>ccRCC = clear cell renal cell carcinoma.</p>
                                <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in
                                any way.</p>
                                `;

const NCCNcalloutData = {
    title: 'Select NCCN Guidelines® for Kidney Cancer',
    showBadge: false,
    bodyCopy: `
        <p>Pembrolizumab (KEYTRUDA) is the only category 2A recommended immunotherapy option post nephrectomy for the adjuvant treatment of patients with<sup>4</sup></p>
        <ul>
            <li><b>Stage II</b> ccRCC with Grade 4 tumors +/- sarcomatoid features</li>
            <li><b>Stage III</b> ccRCC</li>
            <li><b>Stage IV</b> ccRCC after metastasectomy with complete resection of disease within 1 year of nephrectomy</li>
        </ul>
    `,
}

const NCCNcalloutReferences = [
    {
        label: "4.",
        text: `Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer v3.2023. © National Comprehensive Cancer Network, Inc. 2022. All rights reserved. Accessed September 26, 2022. To view the most recent and complete version of the guidelines, go online to NCCN.org.`
    },
];


const pageReferences = [
    {
        label: "1.",
        text: "Choueiri TK, Tomczak P, Park SH, et al; for the KEYNOTE&#8288;-&#8288;564 investigators. Adjuvant pembrolizumab after nephrectomy in renal-cell carcinoma. <i>N Engl J Med.</i> 2021;385(8):683–694."
    },
    {
        label: "2.",
        text: `Powles T, Tomczak P, Park SH, et al. Pembrolizumab versus placebo as post-nephrectomy adjuvant therapy for clear cell renal cell carcinoma (KEYNOTE&#8288;-&#8288;564): 30-month follow-up analysis of a multicentre, randomised, double-blind, placebo-controlled, phase 3 trial. <i>Lancet Oncol.</i> 2022;23(9):1133-1144. doi: 10.1016/S1470-2045(22)00487-9`
    },
    {
        label: "3.",
        text: `Data available on request from Merck, Professional Services-DAP, WP1-27, PO Box 4, West Point, PA 19486-0004. Please specify information package US&#8288;-&#8288;JRC&#8288;-&#8288;00196.`
    },
    {
        label: "4.",
        text: `Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer v3.2023. © National Comprehensive Cancer Network, Inc. 2022. All rights reserved. Accessed September 26, 2022. To view the most recent and complete version of the guidelines, go online to NCCN.org.`
    },
]

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;564: Initial analysis (median 24 months)' },
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;564: Follow-up exploratory analysis (median 30 months)' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;564' },
    { label: 'Patient Inclusion Criteria' },
    { label: 'NCCN Clinical Practice Guidelines In Oncology (NCCN Guidelines®)' }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">KEYTRUDA is the first immunotherapy approved for the adjuvant treatment of patients with RCC at intermediate-high or high risk of recurrence following nephrectomy, or following nephrectomy and resection of metastatic lesions<sup>1</sup></PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>

                            <SecondaryCallout 
                                title="KEYTRUDA significantly reduced the risk of disease recurrence or death by 32% compared with placebo<sup>1</sup>" 
                                text="HR<sup>a</sup>=0.68 (95% CI, 0.53–0.87); <i>P</i><sup>b</sup>=0.0010<sup>c</sup>" 
                                alt=""    
                            />

                            <KMCurve
                                title="Kaplan-Meier Estimates of Disease-Free Survival (DFS) in KEYNOTE&#8288;-&#8288;564<sup>1</sup>"
                                limitation="Median time from randomization to data cutoff: 24.1 months (range, 14.9–41.5)"
                                hr="HR<sup>a</sup>=0.68 (95% CI, 0.53–0.87); <i>P</i><sup>b</sup>=0.0010<sup>c</sup>"
                                footnotes={KMofDFSFootnotes}
                                definitions="DFS = disease-free survival; HR = hazard ratio; CI = confidence interval; OS = overall survival."
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `
                                            <p>Events observed: 109/496 (22%) with KEYTRUDA vs 151/498 (30%) with placebo.</p>
                                            <p>Median DFS: Not reached for KEYTRUDA or placebo.</p>
                                            <p>At the time of the DFS analysis, OS data were not mature, with 5% deaths in the overall population.</p>
                                        `
                                    },
                                ]}  
                                image={() => <StaticImage 
                                    src="../../assets/arcc-564-km-v5.png" 
                                    placeholder="blurred" 
                                    alt="Kaplan-Meier Estimates of Disease Free Survival in KEYNOTE-564" />} 
                            />
                        </PageSection>

                        <PageSection title={anchorLinkModelData[1].label}>

                            <SecondaryCallout 
                                title="LIMITATIONS: No formal statistical testing was performed for the updated analysis, and, therefore, no conclusions can be drawn<sup>3</sup>" 
                                alt=""    
                                text="HR=0.63 (95% CI, 0.50–0.80)"
                            />

                            <KMCurve
                                title="Kaplan-Meier Estimates of DFS in KEYNOTE&#8288;-&#8288;564<sup>2,3</sup>"
                                limitation="Median time from randomization to data cutoff: 30.1 months (IQR: 25.7–36.7 months)"
                                hr="HR=0.63 (95% CI, 0.50–0.80)"
                                definitions="CI = confidence interval; DFS = disease-free survival; HR = hazard ratio; IQR = interquartile range."
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `
                                            <p>Events observed: 114/496 (23%) with KEYTRUDA vs 169/498 (34%) with placebo</p>
                                            <p>Median DFS: Not reached for KEYTRUDA or placebo</p>
                                            <p>37% reduction in the risk of disease recurrence, metastasis, or death compared with placebo</p>
                                        `
                                    },
                                ]}  
                                image={() => <StaticImage 
                                    src="../../assets/arcc-564-km-followup-v3.png" 
                                    placeholder="blurred" 
                                    alt="Exploratory Analysis: Kaplan-Meier Estimates of DFS in the Adjuvant Treatment of Certain Patients With Renal Cell Carcinoma in KEYNOTE-564" />} 
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[2].label}>
                            <ARCC />
                        </PageSection>
                        
                        <PageSection title={anchorLinkModelData[3].label}>
                            <BoxedDefinitionList 
                                items={patientInclusionCriteriaItems}
                                footnotes={boxedDefinitionListFootnotes}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[4].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                definitions={NCCNcalloutdefinitions}
                                references={NCCNcalloutReferences}
                            />

                            <SecondaryCallout 
                                title="Be proactive in referring or treating your appropriate patients with RCC at intermediate-high or high risk of recurrence post nephrectomy" 
                                alt=""  
                            />
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>
                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>

                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout >
    );
};

export default Page;
